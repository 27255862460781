import React from "react";
import Footer from "../footer/Footer";
const Gastronomia= () =>{

    return(
        <div>
            <br></br>
            <br></br>
            <br></br>
            <img className="img-fluid rounded mb-3 mb-md-0" src="./images/panoramica1.jpg" height={400} width={1400} alt=""/>
            <br></br>
            <br></br>
        <section classNames="py-5">
    <div className="container">
      <h1 className="fw-light">Gastronomía</h1>
      <p className="lead">En esta página podrás indagar en la gastronomía andaluza, pero más que andaluza, Sevillana</p>
    </div>
  </section>

  
<div className="container">

    <div className="row">
      <div className="col-md-7">
        <a>
          <img className="img-fluid rounded mb-3 mb-md-0" src="./images/rabo.jpg" alt=""/>
        </a>
      </div>
      <div className="col-md-5">
        <h3>Rabo de Toro</h3>
        <p>Se fríen los trozos del rabo de toro y cuando están dorados se reservan. En la misma sartén se echan las verduras troceadas, y se dejan pochar. Cuando las verduras estén muy blanditas se agregan el vino tinto, el caldo, las especias y el rabo de toro, dejando cocer a fuego suave por lo menos 3 horas.</p>
        <a className="btn btn-primary" href="https://www.hogarmania.com/cocina/recetas/carnes/rabo-toro-cordobesa-karlos-arguinano.html">Ver receta</a>
      </div>
    </div>


    <hr/>


    <div className="row">
      <div className="col-md-7">
        <a>
          <img className="img-fluid rounded mb-3 mb-md-0" src="./images/huevosFlamenca.jpg" alt=""/>
        </a>
      </div>
      <div className="col-md-5">
        <h3>Huevos a la Flamenca</h3>
        <p>Se elabora en una cazuela de barro, en la cual se estrellan los huevos, añadiendo espárragos o guisantes. Seguidamente se hornea hasta que los huevos cuajen.Una vez cuajados se le añade una rodaja de chorizo o jamón. Se pueden decorar con unos trocitos de alcachofa o pimientos. Se sirve en la misma cazuela de barro, bien caliente.</p>
        <a className="btn btn-primary" href="https://www.directoalpaladar.com/recetas-de-huevos-y-tortillas/huevos-a-flamenca-receta-clasico-versatil-delicioso-cocina-espanola">Ver receta</a>
      </div>
    </div>
    
    <hr/>

    
    <div className="row">
      <div className="col-md-7">
        <a href="#">
          <img className="img-fluid rounded mb-3 mb-md-0" src="./images/tortaInglesa.jpg" alt=""/>
        </a>
      </div>
      <div className="col-md-5">
        <h3>Torta Inglesa de Carmona</h3>
        <p>El origen de la torta inglesa o del inglés se sitúa en los años del 1882-1885 , época durante la cual el arqueólogo George Edward Bonsor, investigaba unas ruinas de la zona de Carmona (Sevilla). Una de las versiones apunta que el arqueólogo se aficionó tanto a un dulce con bizcocho y hojaldre relleno de cidra y decorado con azúcar glás y canela que ya horneaban en la localidad, que enviaba diariamente a su criada a la pastelería, quien pedía el dulce como la torta del inglés, de ahí su nombre.</p>
        <a className="btn btn-primary" href="https://cocinandoentreolivos.com/2015/09/la-torta-del-ingles-o-torta-inglesa-de.html">Ver receta</a>
      </div>
    </div>
    

    <hr/>

    
    <div className="row">

      <div className="col-md-7">
        <a >
          <img className="img-fluid rounded mb-3 mb-md-0" src="./images/caracoles.jpg" alt=""/>
        </a>
      </div>
      <div className="col-md-5">
        <h3>Caracoles</h3>
        <p>Los caracoles se comen en todos los rincones de nuestra geografía, existiendo muchas recetas según la zona. En Sevilla se suelen añadir a los caracoles guindillas, ajos, diversas especias, hinojo, y poleo. Después de dos o tres lavados se cuecen los caracoles y cuando están tiernos se añade el resto de ingredientes, dejando hervir media hora más.</p>
        <a className="btn btn-primary" href="https://www.dietfarma.com/receta/caracoles-sevillanos">Ver receta</a>
      </div>
    </div>
    
    <hr/>
   
  </div>
  <br></br>
    <Footer/>
        </div>
    )
}
export default Gastronomia
import './App.css';
import {Routes, Route, BrowserRouter} from 'react-router-dom';
import Navbar from './componentes/navegacion/Navbar';
import Home from './componentes/paginas/Home';
import AcercaDe from './componentes/paginas/AcercaDe';
import Gastronomia from './componentes/paginas/Gastronomia';
import Tradiciones from './componentes/paginas/Tradiciones';
import Api from './componentes/paginas/api/Api';

function App() {
  return (
    <div className="App">
        <BrowserRouter>
        <Navbar/>
      <Routes>
          <Route path='/' element ={<Home/>}/>
          <Route path='/acercaDe' element ={<AcercaDe/>}/>
          <Route path='/gastronomia' element ={<Gastronomia/>}/>
          <Route path='/tradiciones' element ={<Tradiciones/>}/>
          <Route path='/api' element ={<Api/>}/>
      </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

import React from "react";
import Footer from "../footer/Footer";

const AcercaDe = () => {
  return (
    <div>
      <br></br>
      <br></br>
      <img class="img-fluid rounded mb-4 mb-lg-0" src="./images/amanacer_1.jpg" alt="..." />
      <section class="py-5">
        <div className="container">
          <h1 className="fw-light">Acerca de nosotros</h1>
          <p className="lead">
            ¿Quieres saber más sobre nosotros?, esta es tu sección. Rellena
            nuestro formulario y te enviaremos mas información de lo que
            hacemos, y si tienes alguna pregunta no tienes mas que hacerla, te
            responderemos
          </p>
        </div>
      </section>
      <div className="container px-4 px-lg-5">
      <div className="row gx-4 gx-lg-5 align-items-center my-5" >
                <div className="col-lg-7"><img class="img-fluid rounded mb-4 mb-lg-0" src="./images/giralda_1.jpg" alt="..." /></div>
                <div className="col-lg-5">
                <h1 className="font-weigth-light">¿Quiénes somos?</h1>
                <p>En Sevilla Tour, somos un grupo de jóvenes deseando de enseñarte las mejores cosas de sevilla, darte toda la información y ayudarte a visitar y conocer la ciudad más bonita del mundo</p>
                <p>Comenzamos hace unos meses a crear esta web y ahora queremos hacerla la referente de todos los visitantes, donde podrán conocer a fondo nuestra vida cotidiana, monumentos, calles, recovecos, ... </p>
      
                </div>
            </div>
            <br></br>
            <div className="row gx-4 gx-lg-5 align-items-center my-5">
                <div className="col-lg-7"><img class="img-fluid rounded mb-4 mb-lg-0" src="./images/foto2.png" alt="..." /></div>
                <div className="col-lg-5">
                <h1 className="font-weigth-light">¿Qué buscamos?</h1>
                <p>En Sevilla Tour, estamos apasionados y totalmente enamorados de nuestra ciudad, es por ello que queremos darla a conocer al resto del mundo y aprecien la inmensa belleza de esta</p>
                <p>También queremos que sirva de guía para los turistas, para que encuentren un sitio donde ver cosas importantes de nuestra tierra </p>
      
                </div>
            </div>
            </div>    
      <br></br>
      <div class="container">
        <div class=" text-center mt-5 ">
          <h1>Formulario de contacto Sevilla Tour</h1>
        </div>

        <div className="row ">
          <div className="col-lg-7 mx-auto">
            <div className="card mt-2 mx-auto p-4 bg-light">
              <div className="card-body bg-light">
                <div className="container">
                  <form id="contact-form" role="form">
                    <div className="controls">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label for="form_name">Nombre*</label>
                            <input
                              id="form_name"
                              type="text"
                              name="name"
                              class="form-control"
                              placeholder="Porfavor introduce tu nombre*"
                              required="required"
                              data-error="Se requiere el nombre."
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label for="form_lastname">Apellidos*</label>
                            <input
                              id="form_lastname"
                              type="text"
                              name="surname"
                              class="form-control"
                              placeholder="Porfavor introduce tus apellidos*"
                              required="required"
                              data-error="Se requieren los apellidos."
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label for="form_email">Email*</label>
                            <input
                              id="form_email"
                              type="email"
                              name="email"
                              class="form-control"
                              placeholder="Porfavor introduce tu email *"
                              required="required"
                              data-error="Se requiere un email correcto."
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label for="form_need">Que deseas*</label>
                            <select
                              id="form_need"
                              name="need"
                              class="form-control"
                              required="required"
                              data-error="PEspecifica en que te podemos ayudar."
                            >
                              <option value="" selected disabled>
                                --Selecciona una opción-
                              </option>
                              <option>Tengo alguna duda sobre la página</option>
                              <option>
                                Me gustaría informar sobre un error
                              </option>
                              <option>Me gustaría unirme al proyecto</option>
                              <option>Otro</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label for="form_message">Mensaje*</label>
                            <textarea
                              id="form_message"
                              name="message"
                              className="form-control"
                              placeholder="Escribe aqui tu mensaje... "
                              rows="4"
                              required="required"
                              data-error="Porfavor, déjanos un mensaje."
                            ></textarea>
                          </div>
                        </div>

                        <div class="col-md-12">
                          <input
                            type="submit"
                            className="btn btn-success btn-send  pt-2 btn-block"
                            value="Enviar"
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br></br>
      <Footer />
    </div>
    
  );
};

export default AcercaDe;


import React, { useState, useEffect } from "react";
import Footer from "../../footer/Footer";
import './api.css'
const Api = () => {
  const [jokes, setJokes] = useState([]);

  useEffect(() => {
    fetch("https://official-joke-api.appspot.com/random_ten")
      .then(response => response.json())
      .then(data => setJokes(data));
  }, []);

  return (
    <div>
    <section className="py-5">
    <div className="container">
      <br></br>
      <img className="img-fluid rounded mb-3 mb-md-0" src="./images/setas1.jpg"  alt=""/>
            
      <br></br>
      <h1 className="fw-light">Chistes</h1>
      <p className="lead">En Sevilla, somos muy de nuestra ciudad, de nuestras costumbres, de todo lo que rodea a la palabra Sevilla. Es por eso que te traemos algo muy nuestro, el humor, disfruta de algunos chistes, no muy buenos pero te haran pasar un buen rato y puede que incluso... Aprendas ingles, que seguro que cojeas...</p>
    </div>
  </section>
    <div className="container">
      <div className="row">
        {jokes.map(joke => (
          <div class="container bootstrap snippets bootdeys">
        
          <div class="col-md-6 col-sm-8 content-card" id="enlinea">
              <div class="card-big-shadow">
                  <div class="card card-just-text" data-background="color" data-color="green" data-radius="none">
                      <div class="content">
                          <h6 class="category">{joke.id}</h6>
                          <h4 class="title"><a>{joke.type}</a></h4>
                          <p class="description">{joke.setup}</p>
                          <p>{joke.punchline}</p></div>
                  </div>
              </div>
          </div>
          
  </div>
  
            
        
        ))}
      </div>
    </div>
    <Footer/>
    </div>
  );
};

export default Api;

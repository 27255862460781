import React from "react";
import { Link } from "react-router-dom";
import Carrusel from "../carrusel/Carrusel";
import Footer from "../footer/Footer";
const Home= () =>{

    return(
        <div>
            <Carrusel />
            <br></br>
            <section class="py-5">
        <div class="container">
      <h1 class="fw-light">Pagina principal</h1>
      <p class="lead">En esta página podrás indagar acerca de los monumentos, gastronomía y tradiciones de la ciudad de Sevilla</p>
    </div>
  </section>

  <main role="main">

    <section class="jumbotron text-center">
      <div class="container">
        <h1 class="jumbotron-heading">Monumentos de Sevilla</h1>
        <p class="lead text-muted">En este apartado de nuestra web podrás ver diferentes monumentos que puedes encontrar en la ciudad, descubrirás la magia que hay en Sevilla, sus callejuelas y vistas que realmente te sorprenderán. Ven con nosotros a disfrutar de esta maravillosa ciudad y sus tradiciones</p>
      </div>
    </section>

    <div class="album py-5 bg-light">
      <div class="container">

        <div class="row">
          <div class="col-md-4">
            <div class="card mb-4 box-shadow">
              <img class="card-img-top" src="./images/alcazar.jpg" alt="Card image cap"/>
              <div class="card-body">
                <h5>El Alcázar de Sevilla</h5>
                <p class="card-text"> Se construyó como fortaleza para proteger a las autoridades musulmanas, y actualmente es el palacio real en uso más antiguo de Europa. Su extensa historia se refleja en la variedad de su arquitectura, donde se mezcla lo barroco con lo renacentista, romántico, gótico o mudéjar.</p>
                <Link className="btn-link" to={'https://es.wikipedia.org/wiki/Real_Alc%C3%A1zar_de_Sevilla'}>
                  <button type="button" class="btn btn-outline-info" >+Info</button>
                </Link>
                <div class="d-flex justify-content-between align-items-center">
                
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card mb-4 box-shadow">
             <img class="card-img-top" src="./images/giralda.jpg" alt="Card image cap"/>
              <div class="card-body">
                <h5>La Giralda</h5>
                <p class="card-text">La Giralda es el campanario de la catedral de Sevilla y la torre más representativa de la ciudad. Mide 94,69 m metros de altura.120​ de altura y fue iniciada en el siglo xii como alminar almohade de la mezquita mayor.en el siglo xvi, con las obras de la catedral cristiana, se añadió el esbelto cuerpo de campanas renacentista y además se construyó un remate en forma de estatua que representa la Fe.</p>
                <Link className="btn-link" to={'https://es.wikipedia.org/wiki/Giralda'}>
                  <button type="button" class="btn btn-outline-info" >+Info</button>
                </Link>
                <div class="d-flex justify-content-between align-items-center">
                 
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card mb-4 box-shadow">
              <img class="card-img-top" src="./images/torreOro.jpg" alt="Card image cap"/>
              <div class="card-body">
                <h5>Torre del Oro</h5>
                <p class="card-text">La Torre del Oro de Sevilla es una torre albarrana situada en el margen izquierdo del río Guadalquivir. Su altura es de 36 metros. Se construyó en el año 617 de la Hégira. Esto es en nuestro calendario los años 1220 y 1221.​ Con su construcción se dio por completado el sistema defensivo de la ciudad almohade y fue el punto más importante, ya que defendía al puerto.</p>
                <Link className="btn-link" to={'https://es.wikipedia.org/wiki/Torre_del_Oro'}>
                  <button type="button" class="btn btn-outline-info" >+Info</button>
                </Link>
                <div class="d-flex justify-content-between align-items-center">
                 
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="card mb-4 box-shadow">
              <img class="card-img-top" src="./images/setas.jpg" alt="Card image cap"/>
              <div class="card-body">
                <h5>Las Setas de la Encarnación</h5>
                <p class="card-text">Las setas fueron construidas en 2006, con el propósito de dar cabida a un espacio cultural en el centro de la ciudad. Su plaza mayor ha sido concebida para grandes eventos y el sótano que alberga un museo con ruinas reales de la época romana, el Antiquarium. Tiene una altura aproximada de 26 metros. Fueron diseñadas por Jürgen Mayer.</p>
                <Link className="btn-link" to={'https://es.wikipedia.org/wiki/Setas_de_Sevilla'}>
                  <button type="button" class="btn btn-outline-info" >+Info</button>
                </Link>
                <div class="d-flex justify-content-between align-items-center">
                 
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card mb-4 box-shadow">
              <img class="card-img-top" src="./images/huevoColon.jpg" alt="Card image cap"/>
              <div class="card-body">
                <h5>Nacimiento de Un Hombre Nuevo</h5>
                <p class="card-text">Conocido popularmente por su aspecto con el nombre de El Huevo de Colón. El conjunto representa un gran huevo formado por las velas de las naves del almirante, en cuyo interior, se sitúa una estatua de Cristóbal Colón, el cual sostiene un mapa desenrollado desde una mano a otra, y sobre el cual, se disponen las tres carabelas.</p>
                <Link className="btn-link" to={'https://es.wikipedia.org/wiki/Huevo_de_Col%C3%B3n_(Sevilla)'}>
                  <button type="button" class="btn btn-outline-info" >+Info</button>
                </Link>
                <div class="d-flex justify-content-between align-items-center">
                 
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card mb-4 box-shadow">
              <img class="card-img-top" src="./images/parqueMaria.jpg" alt="Card image cap"/>
              <div class="card-body">
                <h5>Parque María Luisa</h5>
                <p class="card-text">El Parque de María Luisa se originó en el año 1849, cuando Antonio de Orléans, duque de Montpensier y su esposa, la infanta María Luisa Fernanda de Borbón adquirieron el palacio de San Telmo incluido y sus jardines y lo hicieron su residencia, de modo que contiene algunos árboles de más de 150 años.. Tiene un total de 340.000 metros cuadrados (34 hectáreas).</p>
                <Link className="btn-link" to={'https://es.wikipedia.org/wiki/Parque_de_Mar%C3%ADa_Luisa'}>
                  <button type="button" class="btn btn-outline-info" >+Info</button>
                </Link>
                <div class="d-flex justify-content-between align-items-center">
               
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="card mb-4 box-shadow">
              <img class="card-img-top" src="./images/atarazanas.jpg" alt="Card image cap"/>
              <div class="card-body">
                <h5>Las Reales Atarazanas de Sevilla</h5>
                <p class="card-text">Fueron construidas en 1252 por mandato de Alfonso X el Sabio, extramuros, apoyándose en el cobijo que ofrecía la muralla y la coracha saliente, que unía las Torres del Oro y de la Plata, entre las Puertas del Carbón y del Aceite. Además de como astillero, la Corona usó las Atarazanas para otras funciones, debido a su gran tamaño, sirvieron para albergar asambleas y festejos públicos. También para almacenar el botín y los prisioneros capturados por las flotas de los reyes. En ocasiones sirvieron de cárcel para la élite social.</p>
                <Link className="btn-link" to={'https://es.wikipedia.org/wiki/Atarazanas_de_Sevilla'}>
                  <button type="button" class="btn btn-outline-info" >+Info</button>
                </Link>
                <div class="d-flex justify-content-between align-items-center">
                 
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card mb-4 box-shadow">
              <img class="card-img-top" src="./images/plazaEspana.jpg" alt="Card image cap"/>
              <div class="card-body">
                <h5>Plaza de España</h5>
                <p class="card-text">La Plaza de España es un espectáculo de luz y majestuosidad. Encuadrada en el Parque de María Luisa, esta plaza fue diseñada por el gran arquitecto sevillano Aníbal González como espacio emblemático de la Exposición Iberoamericana de 1929. Cuenta con una grandiosa fuente en el centro de la misma y con 48 bancos que representan, por orden alfabético, cuarenta y seis provincias españolas), con su escudo, un mapa y un paño de azulejo pisano con hechos históricos destacados de ese territorio. </p>
                <Link className="btn-link" to={'https://es.wikipedia.org/wiki/Plaza_de_Espa%C3%B1a_(Sevilla)'}>
                  <button type="button" class="btn btn-outline-info" >+Info</button>
                </Link>
                <div class="d-flex justify-content-between align-items-center">
                 
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card mb-4 box-shadow">
              <img class="card-img-top" src="./images/puenteTriana.jpg" alt="Card image cap"/>
              <div class="card-body">
                <h5>Puente de Isabel II</h5>
                <p class="card-text">Fue construido entre 1845 y 1852 por los ingenieros franceses Gustavo Steinacher y Ferdinand Bennetot. Esta construcción sustituyó al Puente de Barcas. Para el diseño, los ingenieros se inspiraron el puente del Carrousel, el cual desaparecería años más tarde.La estructura se compone de cuatro bases de piedra, dos pilas centrales y dos estribos laterales, entre las que se abren tres vanos iguales, contando además con un arco marinero también de sillería.</p>
                <Link className="btn-link" to={'https://es.wikipedia.org/wiki/Puente_de_Isabel_II'}>
                  <button type="button" class="btn btn-outline-info" >+Info</button>
                </Link>
                <div class="d-flex justify-content-between align-items-center">
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </main>
<br></br>

            <Footer />
        
        </div>
    )
}
export default Home
import React from 'react'
import {Link} from 'react-router-dom'

const Footer =()=>{

    return(
        <div>
            <footer className='text-white py-4 bg-dark'>
                <div className='container'>
                    <nav className='row'>
                        <Link to='/' className='col-12 col-md-3 d-flex aling-items-center justify-content-center'>
                            <img src='escudoSevilla.png' className='mx-2' height={80} width={100}/>
                        </Link>
                        <ul className='col-12 col-md-3 list-unstyled'>
                            <li className='font-weight-bold mb-2'> Sevilla en estado puro</li>
                            <li className='text-center'>Todo lo mejor de sevilla en una página</li>
                        </ul>
                        <ul className='col-12 col-md-3 list-unstyled'>
                            <li className='font-weight-bold mb-2'>Enlaces</li>
                            <li className="nav-item">
                                <Link className="nav-link active" to='/' class='text-reset'>Inicio</Link>  
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to='/acercaDe' class='text-reset'>Acerca de nosotros</Link> 
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to='/gastronomia' class='text-reset'>Gastronomia</Link> 
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to='/tradiciones' class='text-reset'>Tradiciones</Link> 
                            </li>
                        </ul>
                        <ul className='col-12 col-md-3 list-unstyled'>
                            <li className='font-weight-bold mb-2'>Siguenos en nuestras redes</li>
                            <li className='d-flex justify-content-between'>
                                <Link to={'https://es-es.facebook.com/'}>
                                    <i className="bi bi-facebook"/>
                                </Link>
                                <Link to={'https://www.instagram.com/'}>
                                    <i className="bi bi-instagram"/>
                                </Link>
                                <Link to={'https://twitter.com/?lang=es'}>
                                    <i className="bi bi-twitter"/>
                                </Link>
                                <Link to={'https://www.youtube.com/watch?v=XqaU8vs0nQw'}>
                                <   i className="bi bi-youtube"/>
                                </Link>
                            </li>
                        </ul>
                    </nav>
                </div>
            </footer>
        </div>
    )

}
export default Footer
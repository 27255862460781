import React from "react";
import Footer from "../footer/Footer";

const Tradiciones= () =>{

    return(
        <div>
            <br></br>
            <br></br>
            <br></br>
            <img className="img-fluid rounded mb-3 mb-md-0" src="./images/torresevilla_.jpg"  alt=""/>
            <br></br>
            <br></br>
        <section className="py-5">
    <div className="container">
      <h1 className="fw-light">Tradiciones</h1>
      <p className="lead">Aquí podrás conocer la historia de nuestras tradiciones más famosas, conocerás a fondo nuestro sentir y también te tenemos preparado un apartado de curiosidades.</p>
    </div>
  </section>

        
        <div className="container px-4 px-lg-5">
            
            <div className="row gx-4 gx-lg-5 align-items-center my-5">
                <div className="col-lg-7"><img class="img-fluid rounded mb-4 mb-lg-0" src="./images/feria.jpg" alt="..." /></div>
                <div className="col-lg-5">
                    <h1 className="font-weight-light">FERIA DE SEVILLA</h1>
                    <p>La Feria de Sevilla es una de las más famosas tradiciones que se toman en la ciudad, niños, abuelos, padres viven la feria y el ambiente como uno de sus momentos favoritos. </p>
                   
                </div>
            </div>
                             
            <div className="row gx-4 gx-lg-5">
                <div className="col-md-4 mb-5">
                    <div className="card h-100">
                        <div className="card-body">
                            <h2 className="card-title">Ubicación</h2>
                            <p className="card-text">La Feria de Abril se monta en el otro lado del río Guadalquivir al centro de Sevilla, junto al barrio de Los Remedios. Se puede ir andando desde el centro, aunque lo mejor será que guardes las fuerzas para la Feria y cojas el transporte público.</p>
                        </div>
                       
                    </div>
                </div>
                <div className="col-md-4 mb-5">
                    <div className="card h-100">
                        <div className="card-body">
                            <h2 className="card-title">Historia</h2>
                            <p className="card-text">Fue creada por iniciativa de dos concejales que solicitaron al Pleno la recuperación de las ferias de Sevilla, una en abril y otra en septiembre. Lo de recuperar tenía todo el sentido ya que el permiso para celebrar ambas lo otorgó Alfonso X el Sabio en 1254, seis años después de que su padre conquistara Sevilla. Se aprobó por el Ayuntamiento el día 18 de septiembre de 1846 y se inauguró el 18 de abril de 1847 en el Prado de San Sebastián, con 19 casetas.</p>
                        </div>
                        
                    </div>
                </div>
                <div className="col-md-4 mb-5">
                    <div className="card h-100">
                        <div className="card-body">
                            <h2 className="card-title">Curiosidades</h2>
                            <p className="card-text">1. El Real de la Feria lleva este nombre porque era un real era el precio que cobraban los cocheros de caballos por trasladar a las personas hasta el recinto ferial.</p>
                                              <p>2. Por su parte, la calle del Infierno se llama así por el ruido que provocan la cantidad de atracciones que hay en ella. ¡Más de 100 atracciones!</p>
                                              <p>3. La decoración de las casetas se cuida hasta tal punto que el que el ayuntamiento otorga unos premios a las que están mejor adornadas.</p>
                                              <p>4. En sus comienzos la Feria se celebraba con el objetivo de comprar y vender ganado. Fueron un vasco y un catalán quienes promovieron este evento y en 1846 pidieron permiso para llevarla a cabo.</p>
                                              <p>5. Los farolillos de papel de la Feria de Abril encuentran sus orígenes en el año 1877, cuando la Reina Isabel II hizo una visita al acontecimiento sevillano.</p>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
       
        <div className="container px-4 px-lg-5">
           
            <div className="row gx-4 gx-lg-5 align-items-center my-5">
                <div className="col-lg-7"><img class="img-fluid rounded mb-4 mb-lg-0" src="./images/semanaSanta.jpg" alt="..." /></div>
                <div className="col-lg-5">
                    <h1 className="font-weight-light">SEMANA SANTA</h1>
                    <p>La Semana Santa de Sevilla es la mejor representación cristiana de la Pasión, Muerte y Resurrección de Cristo. Para los creyentes es la semana mas importante del año, donde todos los Cofrades salen a la calle a ver las hermandades pasearse por las hermosas calles de Sevilla. </p>
                   
                </div>
            </div>
                             
            <div className="row gx-4 gx-lg-5">
                <div className="col-md-4 mb-5">
                    <div className="card h-100">
                        <div className="card-body">
                            <h2 className="card-title">Ubicación</h2>
                            <p className="card-text">La Semana Santa se sitúa como núcleo en pleno centro de la ciudad, donde se encuentra la Carrera Oficial, por donde pasan todas las hermandades obligatoriamente, los recorridos están establecidos por el Consejo de Hermandades y puedes comporbarlos en un programa de mano donde vienen todos y cada uno de los recorridos con horas de llegada e información relevante de todas las hermandades de cada día, que podrás conseguir en tu kiosko de confianza.</p>
                        </div>
                       
                    </div>
                </div>
                <div className="col-md-4 mb-5">
                    <div className="card h-100">
                        <div className="card-body">
                            <h2 className="card-title">Historia</h2>
                            <p className="card-text">Todo parece indicar que la primera procesión de Semana Santa de Sevilla se inició con el Vía Crucis a la Cruz del Campo, una costumbre que instauró el Marqués de Tarifa, Fadrique Enríquez de Ribera. En 1521 llegó a la ciudad procedente de Jerusalén.  60 hermandades procesionan hacia la catedral de la ciudad​ y 11 hermandades procesionan dos días antes, el Viernes de Dolores y el Sábado de Pasión, sin pasar por la catedral.

                                En 1980 fue declarada de Interés Turístico Internacional.​ Constituye una de las grandes fiestas de primavera de la ciudad, junto con la Feria de Abril.</p>
                        </div>
                        
                    </div>
                </div>
                <div className="col-md-4 mb-5">
                    <div className="card h-100">
                        <div className="card-body">
                            <h2 className="card-title">Curiosidades</h2>
                            <p className="card-text">1. De todas las imágenes, la única realizada con barro es la Virgen de las Aguas.</p>
                                              <p>2. Todas las Hermandades realizan largas estaciones de penitencia, pero el recorrido más largo es el de la Hermandad del Cerro del Águila. Por otro lado, la Hermandad que menos tiempo recorre las calles sevillanas es El Santo Entierro que dura unas dos horas y media.</p>
                                              <p>3. El único Cristo que procesiona de espaldas a la marcha es el de la Hermandad del Dulce más conocida como ‘La Bofetá’.</p>
                                              <p>4. La Hermandad de los Panaderos, para hacer honor a su noble oficio regala picos de pan.</p>
                                              <p>5. La Virgen de la Esperanza de Triana lleva un flotador en la manga debido a que su sede es la Capilla de los Marineros y a la fuerte conexion que tiene con los antiguos marineros del barrio de Triana.</p>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>

        <br></br>
        <Footer/>
        </div>
    )
}
export default Tradiciones
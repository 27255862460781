import React from "react";
import { Link } from "react-router-dom";

const Navbar= () =>{

    return(
        <div>
        <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
    <div className="container">
                <Link to='/'>
                    <img src='./escudoSevilla.png' width='50'/>
                </Link>
      <a className="navbar-brand" href="/">Sevilla Tour</a>
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarResponsive">
        <ul className="navbar-nav ms-auto">
          <li className="nav-item active">
            <Link className="nav-link active" to='/'>Home</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to='/acercaDe'>Acerca de nosotros</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to='/gastronomia'>Gastronomia</Link>
          </li>
          <li className="nav-item">
          <Link className="nav-link" to='/tradiciones'>Tradiciones</Link>
          </li>
          <li className="nav-item">
          <Link className="nav-link" to='/api'>Api de chistes</Link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  
</div>
    );
}
export default Navbar